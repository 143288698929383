<template>
	<div class="category-selection">
		<ZyroLabel>
			{{ $t('builder.userStyles.colors.colorsShades') }}
		</ZyroLabel>
		<ZyroColorPicker
			v-for="(colorCategory, index) in COLORS"
			:ref="`colorPicker${index}`"
			:key="`${colorCategory[MAIN_CATEGORY_COLOR_INDEX]}${index}`"
			:value="transformColorToVariable(colorCategory[MAIN_CATEGORY_COLOR_INDEX])"
			is-portal
			is-popup
			show-footer
			:default-mode="COLOR_PICKER_MODE_CUSTOM_COLOR"
			disable-alpha
			@save="setColorWithShades($event, colorCategory[MAIN_CATEGORY_COLOR_INDEX])"
		>
			<template
				#trigger="{ toggleColorPicker }"
			>
				<ZyroButton
					class="color-set color-set--hovered color-set--no-margin"
					data-qa="builder-sidemenu-globalstyles-colors-maincolorssetter"
					@click="toggleColorPicker"
				>
					<div
						:style="{ backgroundColor: transformColorToVariable(colorCategory[MAIN_CATEGORY_COLOR_INDEX]) }"
						class="main-color"
					/>
					<div class="separator" />
					<div class="color-set__colors-container">
						<div
							v-for="(color, colorIndex) in colorCategory"
							:key="`${color}${colorIndex}`"
							class="color-set__color"
							:style="{ backgroundColor: transformColorToVariable(color) }"
						/>
					</div>
				</ZyroButton>
			</template>
		</ZyroColorPicker>
		<ZyroButton
			v-qa="'userstyles-colors-resetcolorpalette-openmodal-btn'"
			theme="primary"
			icon-left="refresh-icon"
			color="white"
			class="category-selection__restore-button"
			@click="openModal({ name: USER_COLORS_RESET_MODAL })"
		>
			{{ $t('builder.userStyles.colors.resetBtn') }}
		</ZyroButton>
	</div>
</template>

<script>
import { useColors } from '@/components/builder-drawers/drawers/partials/use/useColors';
import { COLOR_PICKER_MODE_CUSTOM_COLOR } from '@/components/global/zyro-color-picker/constants';
import { USER_COLORS_RESET_MODAL } from '@/store/builder/constants/modals';
import {
	mapActionsGui,
	OPEN_MODAL,
} from '@/store/builder/gui';
import { transformColorToVariable } from '@/utils/colorVariableModifiers';

const MAIN_CATEGORY_COLOR_INDEX = 1;

const PRIMARY_COLORS = [
	'primary-dark',
	'primary',
	'primary-accent',
	'primary-light',
];
const SECONDARY_COLORS = [
	'secondary-dark',
	'secondary',
	'secondary-accent',
	'secondary-light',
];
const ACCENT1_COLORS = [
	'accent-1-dark',
	'accent-1',
	'accent-1-accent',
	'accent-1-light',
];
const ACCENT2_COLORS = [
	'accent-2-dark',
	'accent-2',
	'accent-2-accent',
	'accent-2-light',
];

const COLORS = [
	PRIMARY_COLORS,
	SECONDARY_COLORS,
	ACCENT1_COLORS,
	ACCENT2_COLORS,
];

export default {
	setup() {
		const { setColorWithShades } = useColors();

		return {
			setColorWithShades,
			transformColorToVariable,
			COLOR_PICKER_MODE_CUSTOM_COLOR,
			COLORS,
			USER_COLORS_RESET_MODAL,
			MAIN_CATEGORY_COLOR_INDEX,
		};
	},
	methods: { ...mapActionsGui({ openModal: OPEN_MODAL }) },
};
</script>

<style scoped lang="scss">
@import './Colors';
@import '@/components/builder-drawers/drawers/partials/stylesDrawer/UserStyles.scss';
$color-height: 24px;

.main-color {
	width: 24px;
	height: 24px;
	border: 1px solid $grey-200;
	border-radius: $border-radius-round;
}

.separator {
	height: $color-height;
	margin: 0 8px;
	border-left: 1px solid $grey-400;
}
</style>
